<template>
    <div>
      <Footer></Footer>
        <div class="content">
          <div class="content_box">
           <span class="content_box_tilte"> 第十届“大唐杯”全国大学生新一代信息通信技术大赛 官方通知发布</span>
           <div class="content_box_BOX"> 
                <span class="content_inform">官方通知</span>
                <div class="content_text">
                  <p><el-link type="info" @click="openfile1">关于邀请参加第十届“大唐杯”全国双师型教师新一代信息通信技术大赛的通知</el-link></p>
                  <p><el-link type="info" @click="openfile2">关于邀请参加第十届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛的通知</el-link></p>
                  <p><el-link type="info" @click="openfile3">关于邀请参加第十届“大唐杯”全国大学生新一代信息通信技术大赛的通知</el-link></p>
                </div>
           </div>
          </div>
  
  
          <div class="content_box">
           <span class="content_box_tilte"> 第十届“大唐杯”全国大学生新一代信息通信技术大赛 官方文件发布</span>
           <div class="content_box_BOXTOW"> 
                <span class="content_inform">官方文件</span>
                <div class="content_text">
                  <p><el-link type="info" @click="openfile4">第十届“大唐杯”全国双师型教师新一代信息通信技术大赛-竞赛大纲</el-link></p>
                  <p><el-link type="info" @click="openfile5">第十届“大唐杯”全国双师型教师新一代信息通信技术大赛获奖名单公布</el-link></p>
                  <p><el-link type="info" @click="openfile6">第十届“大唐杯”全国大学生新一代信息通信技术大赛-省赛获奖名单公布</el-link></p>
                  <p><el-link type="info" @click="openfile7">第十届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛-竞赛大纲</el-link></p>
                  <p><el-link type="info" @click="openfile8">第十届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛获奖名单公布</el-link></p>
                  <p><el-link type="info" @click="openfile9">第十届大唐杯全国大学生新一代信息通信技术大赛（省赛）-信息通信工程实践赛道竞赛大纲</el-link></p>
                </div>
           </div>
          </div>
        </div>
    <Bottom></Bottom>
    </div>
  </template>
  
  <script>
  import Footer from '@/components/Footer/Footer.vue'
  import Bottom from '@/components/Bottom/Bottom.vue'
  import $ from "jquery";
  export default {
    name: 'Home',
    components: {
      Footer,
      Bottom
    },
    data(){
      return {
  
      }
    },
    created(){},
    methods:{
      openfile1(){
        window.open('./static/Tenth/关于邀请参加第十届“大唐杯”全国双师型教师新一代信息通信技术大赛的通知.pdf')
      },
      openfile2(){
        window.open('./static/Tenth/关于邀请参加第十届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛的通知.pdf')
  
      },
      openfile3(){
        window.open('./static/Tenth/关于邀请参加第十届“大唐杯”全国大学生新一代信息通信技术大赛的通知.pdf')
  
      },
      openfile4(){
        window.open('./static/Tenth/第十届“大唐杯”全国双师型教师新一代信息通信技术大赛-竞赛大纲.pdf')
  
      },
      openfile5(){
        window.open('./static/Tenth/第十届“大唐杯”全国双师型教师新一代信息通信技术大赛获奖名单公布.pdf')
  
      },
      openfile6(){
        window.open('./static/Tenth/第十届“大唐杯”全国大学生新一代信息通信技术大赛-省赛获奖名单公布.pdf')
  
      },
      openfile7(){
        window.open('./static/Tenth/第十届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛-竞赛大纲.pdf')
  
      },
      openfile8(){
        window.open('./static/Tenth/第十届“大唐杯”全国大学生新一代信息通信技术大赛全国总决赛获奖名单公布.pdf')
  
      },
      openfile9(){
        window.open('./static/Tenth/第十届大唐杯全国大学生新一代信息通信技术大赛（省赛）-信息通信工程实践赛道竞赛大纲.pdf')
  
      },
    },
    mounted(){
  
    },
    created(){
  
    },
  }
  </script>
  
  <style scoped lang="scss">
  /deep/ .el-table .cell.el-tooltip{
    text-align: left;
  }
  /deep/.el-link__inner{
    color: #000;
  }
  /deep/.el-link__inner:hover{
    color: orangered;
  }
  
  @import './Tenth.scss';
  </style>